<template>
  <div v-if="statistics.length" class="content-statistics">
    <div class="filter mb-2">
      <v-select
        v-model="choseUser"
        placeholder="Выбрать сотрудника"
        label="employee"
        :options="users"
      ><span slot="no-options">Сотрудники не найдены</span></v-select>
    </div>

    <b-table
      responsive
      :items="statistics"
      :fields="fields"
      class="mb-0"
    >
      <template #cell(status)="data">
        <b-badge
          pill
          :variant="data.value.variant"
        >
          {{ data.value.status }}
        </b-badge>
      </template>

      <template #cell(Phone)="data">
        <span class="text-nowrap">
          {{ data.value }}
        </span>
      </template>

      <!-- Optional default data cell scoped slot -->
      <template #cell()="data">
        {{ data.value }}
      </template>
    </b-table>
  </div>
  <div v-else class="empty">
    Нет данных
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { coreApi } from '@/api'
import { BTable } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BTable,
    vSelect
  },
  data() {
    return {
      choseUser: '',
      listStatus: {
        1: 'Провален',
        2: 'Пройден',
        3: 'Завершен заранее'
      },
      fields: [
        { key: 'employee', label: 'Сотрудник' },
        { key: 'test', label: 'Тест' },
        { key: 'date', label: 'Дата' },
        { key: 'rightAnswer', label: 'Правильных ответов' },
        { key: 'time', label: 'Время прохождения' },
        { key: 'status', label: 'Результат' }
      ],
      statistics: [],
      fullStatistics: []
    }
  },
  watch: {
    choseUser() {
      if (this.choseUser) {
        this.statistics = this.fullStatistics.reverse().filter((item) => item.employee === this.choseUser)


      }
    }
  },
  computed: {
    ...mapState('main', ['user', 'tests']),
    users() {
      let res = [];
      for (let user of this.fullStatistics) {
        const isUser = res.find(item => item == user.employee)

        if (isUser === undefined) {
          res.push(user.employee)
        }
      }

      return res;
    }
  },
  methods: {
    getStatusItem(idStatus) {
      let variant = 'danger'
      if (idStatus == 2) variant = 'success'
      if (idStatus == 3) variant = 'warning'

      return {
        status: this.listStatus[idStatus],
      }
    },
    getTime(timestamp) {
      let message = ''
      const hours = Math.floor(timestamp / 60 / 60)
      const minutes = Math.floor(timestamp / 60) - (hours * 60)
      const seconds = timestamp % 60

      if (hours) message += `${hours} ч `
      if (minutes) message += `${minutes} мин `
      if (seconds) message += `${seconds} сек`

      return message || '--'
    }
  },
  async mounted() {
    this.isLoad = true;
    const employees = await coreApi.employees(this.user.id)
    let statistic = await coreApi.getStatistics(this.user.id)

    if (statistic) {
      statistic = statistic?.map((item) => {
        const employee = employees.find((employee) => employee.id == item.user)
        const test = this.tests.find((test) => test.hash == item.test)
        const status = this.getStatusItem(item.status)

        return {
          employee: employee?.name || 'Сотрудник удален',
          test: test?.title || 'Тест не найден',
          date: item.dateCreate,
          rightAnswer: item.rightAnswer,
          time: this.getTime(item.passedFor),
          status
        }
      })

      this.statistics = statistic.reverse()
      this.fullStatistics = statistic.reverse()
    }

  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
